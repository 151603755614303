
import { computed, defineComponent, PropType } from 'vue';

import ButtonComponent from '@/component/Button.component.vue';
import LinkComponent from '@/component/Link.component.vue';
import FileIcon from '@/component/icon/FileIcon.component.vue';

import { BlogPost } from '@/model/BlogPost.model';

export default defineComponent({
    name: 'BlogPostComponent',

    components: {
        ButtonComponent,
        LinkComponent,
        FileIcon,
    },

    props: {
        blogPost: {
            type: Object as PropType<BlogPost>,
            required: true,
        },
    },

    setup(props) {
        const displayPostedAt = computed<string>(() => props.blogPost.postedAt.format('Do MMMM YYYY'));
        const displayPostedAtDifference = computed<string>(() => props.blogPost.postedAt.fromNow());
        const url = computed<string>(() => `/blog/post/${props.blogPost.urlSlug}`);

        return {
            displayPostedAt,
            displayPostedAtDifference,
            url,
        }
    },
});
